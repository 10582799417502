import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import FloatingActions, { Action } from "../../ui/FloatingActions";
import { Add, ArrowBack, Create, Delete, MoreVert } from "@material-ui/icons";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { MANAGER_ROUTES } from "../../utils/constants";
import { CONSENT_TYPES } from "../../utils/consentConstants";
import Title from "../../ui/Title";
import { FormattedMessage, useIntl } from "react-intl";
import { common, confirm } from "../../messages";
import Confirm from "../../ui/Confirm";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { parseBmarkenDate } from "../../utils/utils";

function CreateConsentButton({ trigger }) {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const create = (subtype) => {
    handleClose();
    history.push(
      MANAGER_ROUTES.CONSENT_MANAGEMENT_CREATE +
        "?subtype=" +
        subtype.toLowerCase()
    );
  };

  return (
    <React.Fragment>
      {trigger(handleClick)}
      <Drawer anchor="bottom" open={Boolean(anchorEl)} onClose={handleClose}>
        <List>
          {CONSENT_TYPES.filter((type) => type.value !== "-").map((type) => (
            <MenuItem key={type.value} onClick={() => create(type.value)}>
              <ListItemIcon>{type.icon}</ListItemIcon>
              <ListItemText primary={type.label} />
            </MenuItem>
          ))}
        </List>
      </Drawer>
    </React.Fragment>
  );
}

export default function ConsentManagement() {
  const intl = useIntl();
  const {
    bmapi,
    notifyError,
    notifySuccess,
    startLoading,
    stopLoading,
  } = useBmapi();
  const history = useHistory();
  const [selectedCampaign, setSelectedCampaign] = useState("-");
  const [selectedStatus, setSelectedStatus] = useState("-");
  const [anchorEl, setAnchorEl] = useState(null);
  const [consentId, setConsentId] = useState(null);
  const [consents, setConsents] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [campaignList, setCampaignList] = useState([]);

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  /*useEffect(() => {
    Promise.all([loadCampaigns()]).then(([cmp]) => {
      cmp.unshift({
        name: "Nessuna campagna",
        campaign_id: "-",
        business_name: "",
        isActive: true,
      });
      setCampaignList(cmp);
    });
    // eslint-disable-next-line;
  }, [loadCampaigns]);*/

  useEffect(() => {
    const readCampaigns = async () => {
      let list = await bmapi.getTenantCampaigns();
      list = list.filter(
        (c) =>
          c.type === "CAMPAIGN_MULTIWIN" ||
          c.type === "EARNING_CARD_INSTANT_WIN"
      );
      list.unshift({
        name: "Nessuna campagna",
        id: "-",
        business_name: "",
        isActive: true,
      });
      setCampaignList(list);
    };
    readCampaigns();
  }, [bmapi]);

  const byName = (a, b) => a.title.localeCompare(b.title);

  const getLabel = (type) => {
    const filtered = CONSENT_TYPES.filter((obj) => obj.value === type);
    return filtered && filtered.length ? filtered[0].label : "";
  };

  const submit = (e) => {
    startLoading();
    e.preventDefault();
    const params = {
      status: selectedStatus === "-" ? null : selectedStatus,
      campaign: selectedCampaign === "-" ? null : selectedCampaign,
    };

    for (const property in params) {
      if (params[property] === null) {
        delete params[property];
      }
    }

    bmapi
      .getConsent(params)
      .then((resp) => {
        if (!resp) {
          resp = [];
          setConsents(resp);
        } else {
          setConsents(resp);
        }
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => {
        stopLoading();
      });
  };

  const loadConsents = useCallback(() => {
    startLoading();
    if (bmapi) {
      bmapi
        .getConsent()
        .then((resp) => {
          if (!resp) {
            resp = [];
            setConsents(resp);
          } else {
            setConsents(resp);
          }
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        })
        .finally(() => {
          stopLoading();
        });
    }
  }, [bmapi, intl, notifyError, startLoading, stopLoading]);

  useEffect(() => {
    loadConsents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadConsents]);

  const handleOpen = (e, id) => {
    setAnchorEl(e.currentTarget);
    setConsentId(id);
  };

  const consentDelete = () => {
    startLoading();

    setShowDeleteAlert(false);
    bmapi
      .deleteConsent(consentId)
      .then(() => {
        setConsentId(null);
        notifySuccess("Consenso eliminato con successo");
        loadConsents();
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => {
        stopLoading();
      });
  };

  return (
    <Container maxWidth="xl">
      <Title>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <Box>
            <FormattedMessage
              id="navigation.manager.consentManagement"
              defaultMessage="Gestione Consensi"
            />
          </Box>
        </Box>
      </Title>

      <Container maxWidth="md">
        <Box mb={2}>
          <Card>
            <CardContent>
              <form onSubmit={submit}>
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ width: "50%" }}
                >
                  <TextField
                    select
                    label="Filtra per campagna"
                    value={selectedCampaign}
                    onChange={(e) => setSelectedCampaign(e.target.value)}
                  >
                    {campaignList.map((option) => (
                      <MenuItem key={option.campaign_id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    select
                    label="Filtra per stato"
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                    margin="normal"
                  >
                    <MenuItem value={"-"}>Qualsiasi stato</MenuItem>
                    <MenuItem value={0}>Abilitato</MenuItem>
                    <MenuItem value={1}>Disabilitato</MenuItem>
                  </TextField>
                </Box>
                <Box mt={2}>
                  <FormControl style={{ float: "right" }} margin="normal">
                    <Button variant="contained" color="primary" type="submit">
                      {intl.formatMessage(common.update)}
                    </Button>
                  </FormControl>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Box>
      </Container>
      <Box mt={2} mb={2}>
        <Button
          component={RouterLink}
          to={MANAGER_ROUTES.HOME}
          startIcon={<ArrowBack />}
        >
          {intl.formatMessage(common.backHome)}
        </Button>
      </Box>
      {smallScreen && (
        <React.Fragment>
          {consents.length !== 0 ? (
            <Card>
              <List>
                {consents.sort(byName).map((c, i) => (
                  <React.Fragment key={i}>
                    {i !== 0 && <Divider variant="inset" component="li" />}
                    <ListItem>
                      <ListItemText
                        primary={
                          <>
                            <Typography gutterBottom variant="h6">
                              {c.title}
                            </Typography>
                            <Typography gutterBottom>
                              Tipo: {getLabel(c.type)}
                            </Typography>
                          </>
                        }
                        secondary={
                          parseBmarkenDate(c.from) && parseBmarkenDate(c.to)
                            ? "Dal " +
                              new Date(c.from).toLocaleDateString("it-IT", {
                                day: "numeric",
                                year: "numeric",
                                month: "short",
                              }) +
                              " al " +
                              new Date(c.to).toLocaleDateString("it-IT", {
                                day: "numeric",
                                year: "numeric",
                                month: "short",
                              })
                            : null
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton onClick={(e) => handleOpen(e, c.id)}>
                          <MoreVert />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </React.Fragment>
                ))}
              </List>
            </Card>
          ) : (
            <Card>
              <CardContent style={{ padding: 16 }}>
                Nessun consenso presente
              </CardContent>
            </Card>
          )}
        </React.Fragment>
      )}

      {!smallScreen && (
        <React.Fragment>
          {consents.length !== 0 ? (
            <Card>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>{intl.formatMessage(common.name)}</TableCell>
                      <TableCell>{intl.formatMessage(common.typeOf)}</TableCell>
                      <TableCell>
                        {intl.formatMessage(common.expirationDate)}
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  {consents.sort(byName).map((c) => (
                    <TableBody key={c.id}>
                      <TableRow>
                        <TableCell>{c.title || "-"}</TableCell>
                        <TableCell>{getLabel(c.type)}</TableCell>
                        <TableCell>
                          {c.from === "0001-01-01T00:00:00Z" &&
                          c.to === "0001-01-01T00:00:00Z"
                            ? "-"
                            : `${intl.formatMessage(
                                common.from
                              )} ${intl.formatDate(
                                c.from
                              )} ${intl.formatMessage(
                                common.from
                              )} ${intl.formatDate(c.to)} `}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={(e) => handleOpen(e, c.id)}
                            style={{ padding: 0 }}
                          >
                            <MoreVert />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
                </Table>
              </TableContainer>
            </Card>
          ) : (
            <Card>
              <CardContent style={{ padding: 16 }}>
                Nessun consenso presente
              </CardContent>
            </Card>
          )}
        </React.Fragment>
      )}

      <Menu
        key={consentId}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setConsentId(null);
        }}
        keepMounted
        onClick={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            history.push(
              MANAGER_ROUTES.CONSENT_MANAGEMENT_CREATE + "?id=" + consentId
            );
          }}
        >
          <ListItemIcon>
            <Create />
          </ListItemIcon>
          <Typography variant="inherit">Modifica</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowDeleteAlert(true);
          }}
        >
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <Typography variant="inherit">Elimina</Typography>
        </MenuItem>
      </Menu>

      <Confirm
        open={showDeleteAlert}
        onConfirm={() => {
          consentDelete(consentId);
        }}
        onCancel={() => {
          setConsentId(null);
          setShowDeleteAlert(false);
        }}
        text={intl.formatMessage(confirm.deleteElement)}
      />

      <FloatingActions>
        <CreateConsentButton
          trigger={(onClick) => (
            <Action icon={<Add />} label="Aggiungi consenso" action={onClick} />
          )}
        />
      </FloatingActions>
    </Container>
  );
}
