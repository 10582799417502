import React, { useState, useCallback, useEffect } from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import {
  Apps,
  MoreVert,
  Delete as DeleteIcon,
  Create as CreateIcon,
  Add as AddIcon,
  PinDrop as PinDropIcon,
} from "@material-ui/icons";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { useIntl } from "react-intl";
import Confirm from "../../ui/Confirm";
import { MANAGER_ROUTES, POI_PREFIX } from "../../utils/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { common, confirm } from "../../messages";
import FloatingActions from "../../ui/FloatingActions";
import Title from "../../ui/Title";
import QRCodeGenerator from "qrcode";
import QRCodeDialog from "../../ui/QRCodeDialog";
import { wikiMuseo } from "../../messages/wikiMuseo";

const byName = (a, b) =>
  a.localized_pois[0].title.localeCompare(b.localized_pois[0].title);

export default function ManagePoiList() {
  const [pois, setPois] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [poiSelected, setPoiSelected] = React.useState(null);
  const [poiIdToDelete, setPoiIdToDelete] = React.useState(null);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [code, setCode] = useState(false);
  const history = useHistory();
  const intl = useIntl();
  const { bmapi, notifyError, notifySuccess, businessId } = useBmapi();

  const handleClick = (event, pSel) => {
    setAnchorEl(event.currentTarget);
    setPoiSelected(pSel);
  };

  const handleAddNewPoi = () => {
    history.push(MANAGER_ROUTES.POI);
  };

  const hasChildren = (poiSelected) =>
    !!pois.find((poi) => poi.parent_id === poiSelected.id);

  const getIcon = (poi) => {
    const images = poi.localized_pois[0]?.media_contents?.filter(
      (mediaContent) => mediaContent.media_type === "image"
    );
    let colors =
      poi.tours &&
      poi.tours
        .filter((t) => t.color)
        .sort(function (a, b) {
          return a.id > b.id ? 1 : b.id > a.id ? -1 : 0;
        })
        .map(function (a) {
          return a.color;
        });
    if (colors && colors.length > 0) {
      if (colors.length > 5) {
        colors = ["lightgrey"];
      }
      return images?.length ? (
        <React.Fragment>
          {colors.map(function (a) {
            return (
              <div
                key={a}
                style={{
                  height: "40px",
                  borderRight: "5px solid " + a,
                }}
              ></div>
            );
          })}
          <Avatar
            src={images[0].url}
            style={{
              width: "40px",
              height: "40px",
              margin: "0px 15px",
            }}
          />
        </React.Fragment>
      ) : (
        <PinDropIcon />
      );
    }
    //if there are images in the array show the first otherwise show the icon
    return images?.length ? <Avatar src={images[0].url} /> : <PinDropIcon />;
  };

  const loadPoiList = useCallback(() => {
    if (bmapi) {
      bmapi
        .getPOIs({
          business: businessId,
          lang: bmapi.getUserInfo().business.default_language,
        })
        .then((resp) => {
          if (!resp) {
            resp = [];
            setPois(resp);
          } else {
            setPois(resp);
          }
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    }
  }, [bmapi, intl, notifyError, businessId]);

  useEffect(() => {
    loadPoiList();
  }, [bmapi, loadPoiList]);

  const handleDeletePoi = (poiId) => {
    if (poiId !== null) {
      //loading true
      bmapi
        .deletePOI(poiId)
        .then(() => {
          setShowDeleteAlert(false);
          notifySuccess(intl.formatMessage(common.deleteElement));
        })
        .then(() => loadPoiList())
        .then(() => {
          //loading false
        })
        .catch((e) => {
          //loading false)
          if (e.message === "tours_exist_with_this_poi")
            notifyError(intl.formatMessage(wikiMuseo.removePoiFromTour));
        });
    }
  };

  const handleShowQR = (poiSelected) => {
    /* NEW
    const qrCodeUrl = bmapi.settings.qrCodePrefix.concat("/p/", poiSelected.id);
    QRCodeGenerator.toDataURL(qrCodeUrl, { scale: 16 })
      .then(setCode)
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
    */
    QRCodeGenerator.toDataURL(`${POI_PREFIX}${poiSelected.id}`, { scale: 16 })
      .then(setCode)
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
  };

  return (
    <Container maxWidth="md">
      <Title>Punti di interesse</Title>
      <Box mb={2}>
        <Card>
          <CardContent>
            <List>
              {pois &&
                pois
                  .filter((poisParent) => poisParent.parent_id === "")
                  .map((poiParent, i) => {
                    return (
                      <React.Fragment key={poiParent.id}>
                        {i !== 0 && <Divider key={i} component="li" />}
                        <ListItem key={poiParent.id}>
                          <ListItemAvatar>
                            <PinDropIcon />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <>
                                <Typography
                                  component={"span"}
                                  display="block"
                                  variant="h6"
                                  gutterBottom
                                >
                                  {poiParent.localized_pois[0].title}
                                </Typography>
                                <Typography
                                  component={"span"}
                                  display="block"
                                  gutterBottom
                                >
                                  {poiParent.localized_pois[0].subtitle}
                                </Typography>
                              </>
                            }
                          />
                          <ListItemSecondaryAction key={poiParent.id}>
                            <IconButton
                              onClick={(e) => {
                                handleClick(e, poiParent);
                              }}
                            >
                              <MoreVert />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                        {pois
                          .filter((pois) => pois.parent_id !== "")
                          .sort(byName)
                          .map((poi) =>
                            poiParent.id === poi.parent_id ? (
                              <React.Fragment key={poi.id}>
                                <Divider
                                  key={i}
                                  component="li"
                                  style={{ marginLeft: 50 }}
                                />

                                <ListItem
                                  style={{
                                    minHeight: "90px",
                                  }}
                                >
                                  <ListItemAvatar
                                    style={{
                                      marginLeft: 50,
                                      minHeight: "50px",
                                      display: "flex",
                                    }}
                                  >
                                    {getIcon(poi)}
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={
                                      <>
                                        <Typography
                                          component={"span"}
                                          display="block"
                                          variant="h6"
                                          gutterBottom
                                        >
                                          {poi.localized_pois[0].title}
                                        </Typography>
                                        <Typography
                                          component={"span"}
                                          display="block"
                                          gutterBottom
                                        >
                                          {poi.localized_pois[0].subtitle}
                                        </Typography>
                                      </>
                                    }
                                  />
                                  <ListItemSecondaryAction key={poi.id}>
                                    <IconButton
                                      onClick={(e) => {
                                        handleClick(e, poi);
                                      }}
                                    >
                                      <MoreVert />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              </React.Fragment>
                            ) : null
                          )}
                      </React.Fragment>
                    );
                  })}
            </List>
          </CardContent>
          <Confirm
            open={showDeleteAlert}
            onConfirm={() => handleDeletePoi(poiIdToDelete)}
            onCancel={() => {
              setPoiIdToDelete(null);
              setShowDeleteAlert(false);
            }}
            text={intl.formatMessage(confirm.deleteElement)}
          />
        </Card>
        <Menu
          key={poiSelected}
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          onClose={() => {
            setAnchorEl(null);
            setPoiSelected(null);
          }}
          onClick={() => setAnchorEl(null)}
        >
          <MenuItem
            onClick={() => {
              handleShowQR(poiSelected);
            }}
          >
            <ListItemIcon>
              <Apps fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">
              {intl.formatMessage(common.qrCode)}
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.push(
                MANAGER_ROUTES.POI +
                  "?id=" +
                  poiSelected.id +
                  "&has_children=" +
                  hasChildren(poiSelected)
              );
            }}
          >
            <ListItemIcon>
              <CreateIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">
              {intl.formatMessage(common.modify)}
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setPoiIdToDelete(poiSelected.id);
              setShowDeleteAlert(true);
            }}
            disabled={poiSelected && hasChildren(poiSelected)}
          >
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">
              {intl.formatMessage(common.delete)}
            </Typography>
          </MenuItem>
        </Menu>
      </Box>

      <QRCodeDialog
        code={code}
        setCode={setCode}
        item={poiSelected}
        itemTitle={poiSelected && poiSelected.localized_pois[0].title}
      />

      <FloatingActions>
        <Fab variant="extended" color="primary" onClick={handleAddNewPoi}>
          <AddIcon />
          {intl.formatMessage(common.add)}
        </Fab>
      </FloatingActions>
    </Container>
  );
}
