import React, { useState, useCallback, useEffect } from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { useIntl } from "react-intl";
import {
  Apps,
  MoreVert,
  Delete as DeleteIcon,
  Create as CreateIcon,
  Add as AddIcon,
  Timeline as TimelineIcon,
} from "@material-ui/icons";
import Confirm from "../../ui/Confirm";
import { MANAGER_ROUTES, TOUR_PREFIX } from "../../utils/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { common, confirm } from "../../messages";
import FloatingActions from "../../ui/FloatingActions";
import Title from "../../ui/Title";
import QRCodeGenerator from "qrcode";
import QRCodeDialog from "../../ui/QRCodeDialog";

const byPriority = (a, b) => {
  if (a.priority === b.priority) {
    return a.localized_tours[0].title.localeCompare(b.localized_tours[0].title);
  } else return a.priority - b.priority;
};

export default function ManageTourList() {
  const [tours, setTours] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tourSelected, setTourSelected] = React.useState(null);
  const [tourIdToDelete, setTourIdToDelete] = React.useState(null);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [code, setCode] = useState(false);
  const history = useHistory();
  const intl = useIntl();
  const { bmapi, notifyError, notifySuccess, businessId } = useBmapi();

  const handleClick = (event, tSel) => {
    setAnchorEl(event.currentTarget);
    setTourSelected(tSel);
  };

  const handleAddNewTour = () => {
    history.push(MANAGER_ROUTES.TOUR);
  };

  const getIcon = (tour) => {
    const images = tour.localized_tours[0]?.media_contents?.filter(
      (mediaContent) => mediaContent.media_type === "image"
    );
    //if there are images in the array show the first otherwise show the icon
    return images?.length ? <Avatar src={images[0].url} /> : <TimelineIcon />;
  };

  const loadTourList = useCallback(() => {
    if (bmapi) {
      bmapi
        .getTours({
          business: businessId,
          lang: bmapi.getUserInfo().business.default_language,
        })
        .then((resp) => {
          if (!resp) {
            resp = [];
            setTours(resp);
          } else {
            setTours(resp);
          }
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    }
  }, [bmapi, intl, notifyError, businessId]);

  useEffect(() => {
    loadTourList();
  }, [bmapi, loadTourList]);

  const handleDeleteTour = (tourId) => {
    if (tourId !== null) {
      bmapi
        .deleteTour(tourId)
        .then(() => {
          setShowDeleteAlert(false);
          notifySuccess(intl.formatMessage(common.deleteElement));
        })
        .then(() => loadTourList())
        .then(() => {})
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    }
  };

  const handleShowQR = (tourSelected) => {
    /* NEW
    const qrCodeUrl = bmapi.settings.qrCodePrefix.concat(
      "/t/",
      tourSelected.id
    );
    QRCodeGenerator.toDataURL(qrCodeUrl, { scale: 16 })
      .then(setCode)
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
    */

    QRCodeGenerator.toDataURL(`${TOUR_PREFIX}${tourSelected.id}`, { scale: 16 })
      .then(setCode)
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
  };

  return (
    <Container maxWidth="md">
      <Title>Percorsi</Title>
      <Box mb={2}>
        <Card>
          <CardContent>
            <List>
              {tours &&
                tours.sort(byPriority).map((tour, i) => {
                  return (
                    <React.Fragment key={tour.id}>
                      {i !== 0 && <Divider key={i} component="li" />}
                      <ListItem
                        key={tour.id}
                        style={
                          tour.color
                            ? {
                                borderLeft: "5px solid " + tour.color,
                                margin: "5px 0px",
                              }
                            : {}
                        }
                      >
                        <ListItemAvatar>{getIcon(tour)}</ListItemAvatar>
                        <ListItemText
                          primary={
                            <>
                              <Typography
                                component={"span"}
                                display="block"
                                variant="h6"
                                gutterBottom
                              >
                                {tour.localized_tours[0].title}
                              </Typography>
                              <Typography
                                component={"span"}
                                display="block"
                                gutterBottom
                              >
                                {tour.localized_tours[0].subtitle}
                              </Typography>
                            </>
                          }
                        />
                        <ListItemSecondaryAction key={tour.id}>
                          <IconButton
                            onClick={(e) => {
                              handleClick(e, tour);
                            }}
                          >
                            <MoreVert />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </React.Fragment>
                  );
                })}
            </List>
          </CardContent>
          <Confirm
            open={showDeleteAlert}
            onConfirm={() => handleDeleteTour(tourIdToDelete)}
            onCancel={() => {
              setTourIdToDelete(null);
              setShowDeleteAlert(false);
            }}
            text={intl.formatMessage(confirm.deleteElement)}
          />
        </Card>
        <Menu
          key={tourSelected}
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          onClose={() => {
            setAnchorEl(null);
            setTourSelected(null);
          }}
          onClick={() => setAnchorEl(null)}
        >
          <MenuItem
            onClick={() => {
              handleShowQR(tourSelected);
            }}
          >
            <ListItemIcon>
              <Apps fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">
              {intl.formatMessage(common.qrCode)}
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.push(MANAGER_ROUTES.TOUR + "?id=" + tourSelected.id);
            }}
          >
            <ListItemIcon>
              <CreateIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">
              {intl.formatMessage(common.modify)}
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setTourIdToDelete(tourSelected.id);
              setShowDeleteAlert(true);
            }}
          >
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">
              {intl.formatMessage(common.delete)}
            </Typography>
          </MenuItem>
        </Menu>
      </Box>

      <QRCodeDialog
        code={code}
        setCode={setCode}
        item={tourSelected}
        itemTitle={tourSelected && tourSelected.localized_tours[0].title}
      />

      <FloatingActions>
        <Fab variant="extended" color="primary" onClick={handleAddNewTour}>
          <AddIcon />
          {intl.formatMessage(common.add)}
        </Fab>
      </FloatingActions>
    </Container>
  );
}
