import React, { useEffect, useState } from "react";

//import { Link, Tooltip, Typography } from "@material-ui/core";
import { useBmapi } from "../utils/bmapi-context";
import { JSONTree } from "react-json-tree";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import {
  Button,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import { Settings } from "@material-ui/icons";

const API_URL = "https://bmarken-ai-dot-go12-bmarken-api.oa.r.appspot.com/";
const AssistantID = "asst_50qkvQWF2JPTfhDk3RZGDPyu";
const AssistantName = "BmarkenAI";
const IsWriting = AssistantName + " sta scrivendo...";

export default function CamapignAI() {
  const { notifyError, notifySuccess } = useBmapi();
  const SettingsIcon = Settings;
  const [result, setResult] = useState(null);
  const [newChat, setNewChat] = useState(true);
  const [showConf, setShowConf] = useState(false);
  const [contextInstruction, setContextInstruction] = useState("");
  const [apiDefinition, setApiDefinition] = useState("");
  const [runId, setRunId] = useState("");
  const [threadId, setThreadId] = useState("");
  const [messages, setMessages] = useState([
    {
      message: "Ciao, come posso aiutarti?",
      sentTime: "Adesso",
      direction: "ingoing",
      sender: AssistantName,
    },
  ]);
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    getAssistantConfiguration();
  }, []);

  const handleSendRequest = async (message) => {
    let newMessage = {
      message,
      direction: "outgoing",
      sender: "user",
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setIsTyping(true);

    let apiRequestBody;
    let url;
    let method;

    if (newChat) {
      method = "POST";
      url = API_URL + "assistants/" + AssistantID + "/threads";
      apiRequestBody = {
        message: message,
      };
    } else {
      method = "PUT";
      url = API_URL + "assistants/" + AssistantID + "/threads/" + threadId;
      apiRequestBody = {
        message: message,
        run_id: runId,
      };
    }

    try {
      console.log("chiamo AI!");
      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(apiRequestBody),
      });

      if (!response.ok) {
        // Lancia un errore se lo stato HTTP non è "ok"
        throw new Error(
          "Errore di rete - Stato: " + response.status + " " + response.message
        );
      }

      const resp = await response.json();

      console.log("RESP AI: ", resp);
      setIsTyping(false);
      if (newChat) {
        setRunId(resp.run_id);
        setThreadId(resp.thread_id);
        setNewChat(false);
      }
      if (resp.data.status === "finished") {
        newMessage = {
          message:
            "I dati per la creazione della campagna sono stati raccolti. Refresha la pagina se vuoi iniziare un'altra conversazione.",
          direction: "ingoing",
          sentTime: "Adesso",
          sender: AssistantName,
        };
        setResult(resp.data.payload);
      } else {
        newMessage = {
          message: resp.data.text,
          direction: "ingoing",
          sentTime: "Adesso",
          sender: AssistantName,
        };
      }
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    } catch (err) {
      console.error(err);
      setIsTyping(false);
      notifyError("Errore chiamata aggiorna thread: " + err);
    }
  };

  const getAssistantConfiguration = async () => {
    try {
      let url = API_URL + "assistants/" + AssistantID;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const resp = await response.json();
      console.log(resp);

      setApiDefinition(resp.conf.api_arguments_definition);
      setContextInstruction(resp.conf.main_context);
    } catch (err) {
      console.error(err);
      notifyError("Errore chiamata recuperaSettings: " + err);
    }
  };

  const updateAssistantConfiguration = async () => {
    try {
      let apiRequestBody = {
        main_context: contextInstruction,
        api_arguments_definition: apiDefinition,
      };

      let url = API_URL + "assistants/" + AssistantID;
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(apiRequestBody),
      });

      if (!response.ok) {
        // Lancia un errore se lo stato HTTP non è "ok"
        throw new Error("Errore di rete - Stato: " + response.status);
      }

      const resp = await response.json();
      console.log(resp);
      notifySuccess("Settings aggiornati con successo");
    } catch (err) {
      console.error("Error:", err);
      notifyError("Errore chiamata aggiornaSettings: " + err);
    }
  };

  const handleChangeContext = (event) => {
    setContextInstruction(event.target.value);
  };
  const handleChangeApiDef = (event) => {
    setApiDefinition(event.target.value);
  };

  return (
    <div className="App">
      <div onClick={() => setShowConf(!showConf)}>
        <SettingsIcon />
      </div>
      <DialogContent hidden={!showConf} style={{ margin: "20px" }}>
        <DialogTitle id="customized-dialog-title">
          {"Configurazione assistente"}
        </DialogTitle>
        <TextField
          name="context"
          label={"Istruzione di contesto"}
          value={contextInstruction}
          onChange={handleChangeContext}
          key="context"
          fullWidth={true}
          margin="normal"
          multiline={true}
        />
        <TextField
          name="apiDefinition"
          label={"Descrizione parametri Campagna"}
          value={apiDefinition}
          onChange={handleChangeApiDef}
          key="apiDefinition"
          fullWidth={true}
          margin="normal"
          multiline={true}
        />
        <Button onClick={() => updateAssistantConfiguration()} color="primary">
          {"Aggiorna"}
        </Button>
        <Button onClick={() => setShowConf(!showConf)} color="primary">
          {"Chiudi"}
        </Button>
      </DialogContent>

      <div style={{ position: "relative", height: "700px", width: "500px" }}>
        <MainContainer>
          <ChatContainer>
            <MessageList
              scrollBehavior="smooth"
              typingIndicator={
                isTyping ? <TypingIndicator content={IsWriting} /> : null
              }
            >
              {messages.map((message, i) => {
                return <Message key={i} model={message} />;
              })}
            </MessageList>
            <MessageInput
              placeholder="Send a Message"
              onSend={handleSendRequest}
              disabled={isTyping}
            />
          </ChatContainer>
        </MainContainer>
      </div>
      <div>
        <Typography variant="caption" display="block" gutterBottom>
          Risultato AI:
        </Typography>
        <JSONTree data={result} />
      </div>
    </div>
  );
}
