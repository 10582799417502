import React, { useEffect, useMemo, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";

import {
  AUTH_NAMESPACE,
  CONSUMER_NAMESPACE,
  CONSUMER_ROUTES,
  MANAGER_NAMESPACE,
  MANAGER_ROUTES,
  TENANTS,
  USER_STATUS,
} from "../utils/constants";
import { useBmapi } from "../utils/bmapi-context";
import PageWrapper from "./PageWrapper";
import Navbar from "./Navbar";
import {
  getCallbackUrl,
  getSignInSubscriptionData,
  getSubscribeStep2Path,
  propagateUrlTracing,
} from "../utils/utils";

const isHome = (page) => !!page.home;
const withPath = (page) => !!page.routing?.path;
const byPathLength = (a, b) => b.routing.path.length - a.routing.path.length;

const fredirect = (url) => () => {
  console.info("redirect ##2");
  window.location.href = url;
  return null;
};

function Page({ routing }) {
  if (routing.redirectUrl) {
    return (
      <Route
        exact={routing.exact}
        key={routing.path}
        path={routing.path}
        component={fredirect(routing.redirectUrl)}
      />
    );
  }

  const Wrapper = routing.Wrapper || PageWrapper;
  return (
    <Route exact={routing.exact} key={routing.path} path={routing.path}>
      <Wrapper component={routing.Component} />
    </Route>
  );
}

export default function Routing() {
  const { baseUrl, bmapi, pages } = useBmapi();
  const location = useLocation();
  const history = useHistory();
  const cbUrl = getCallbackUrl();
  const [redirectPage, setRedirectPage] = useState(null);

  useEffect(() => {
    console.log(
      "LOADING, Routing.js: useEffect (1) [",
      location.pathname,
      "] window.location:",
      window.location,
      "location:",
      location
    );
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    console.info("?????0", window.location.href);
    console.log(
      "LOADING, Routing.js: useEffect (2) selfServiceMode=",
      bmapi.settings.selfServiceMode,
      ", cbUrl=",
      cbUrl,
      " [bmapi (defined)=",
      !!bmapi,
      "bmapi.userStatus=",
      bmapi.userStatus,
      "baseUrl=",
      baseUrl,
      "history (defined)=",
      !!history,
      "pages (defined)=",
      !!pages,
      "href=",
      window.location.href,
      "]"
    );

    // callback url

    const cbUrl = getCallbackUrl();
    console.info("?????1");
    if (bmapi.userStatus === USER_STATUS.LOGGED && cbUrl) {
      //history.push(cbUrl.replace(baseUrl, "/"));
      //bmapi.setCallbackUrl(false);
      console.info("redirect ##3", cbUrl);
      window.location.href = cbUrl;
      return;
    }
    const signinSubscriptionData = getSignInSubscriptionData();
    console.info("?????2", signinSubscriptionData, bmapi.userStatus);

    if (
      bmapi.userStatus === USER_STATUS.LOGGED &&
      signinSubscriptionData?.isSubscribing
    ) {
      console.info("?????3", signinSubscriptionData, {
        paymentId: signinSubscriptionData.paymentId,
        subscriptionId: bmapi.subscription?.id,
      });
      const subscribeStep2Path = getSubscribeStep2Path({
        paymentId: signinSubscriptionData.paymentId,
        subscriptionId: bmapi.subscription?.id,
      });
      console.info("redirect ##3.a", subscribeStep2Path);
      setRedirectPage(subscribeStep2Path);
    }
    /*
    if (
      bmapi.userStatus === USER_STATUS.ANONYMOUS &&
      bmapi.isManager() &&
      bmapi.settings.uniqueSignIn
    ) {
      console.info(
        "redirect ##4, baseUrl=",
        baseUrl,
        ", CONSUMER_ROUTES.HOME=",
        CONSUMER_ROUTES.HOME,
        ", url=",
        baseUrl + CONSUMER_ROUTES.HOME.slice(1)
      );
      window.location.href = baseUrl + CONSUMER_ROUTES.HOME.slice(1);
      return;
    }*/
    // TODO: if it's self service and there are no subsciptions, propose to create one
    /*
    let iredirectPage;
    if (
      bmapi.settings.selServiceMode &&
      bmapi.subscriptionSlug === DEV_SUBSCRIPTION &&
      bmapi.app === MANAGER
    ) {
      const arr = pages.filter(
        (page) => page.id === "manager.view_subscriptions"
      );
      if (arr?.length === 1) {
        iredirectPage = arr[0];
      }
    }
    setRedirectPage(iredirectPage);
    console.log(
      "LOADING, Routing.js: useEffect (3) iredirectPage=",
      iredirectPage,
      window.location.href
    );*/
  }, [bmapi, bmapi.userStatus, baseUrl, history, pages, location]);

  const activePages = useMemo(() => pages.filter(withPath).sort(byPathLength), [
    pages,
  ]);

  if (redirectPage && (bmapi.userStatus !== USER_STATUS.LOGGED || !cbUrl)) {
    console.info(
      "redirect ##4.1, touter redirect to MANAGER_ROUTES.VIEW_SUBSCRIPTIONS=",
      MANAGER_ROUTES.VIEW_SUBSCRIPTIONS,
      redirectPage
    );
  }

  const homePage = activePages.find(isHome);

  console.log(
    "LOADING, Routing.js: home page=",
    homePage,
    ", activePages",
    activePages,
    bmapi,
    window.location.href,
    bmapi.userStatus
  );

  return (
    (bmapi.userStatus !== USER_STATUS.LOGGED || !cbUrl) && (
      <>
        <Switch>
          <Route path={MANAGER_NAMESPACE + AUTH_NAMESPACE} />
          <Route path={CONSUMER_NAMESPACE + AUTH_NAMESPACE} />
          <Route component={Navbar} />
        </Switch>
        <Switch>
          {/* !!redirectPage && (
            <>
              {Page(redirectPage)}
              <Redirect to={MANAGER_ROUTES.VIEW_SUBSCRIPTIONS} />
            </>
          )*/}
          {activePages.map(Page)}
          {redirectPage && <Redirect to={redirectPage} />}
          {bmapi.tenant === TENANTS.LUCCA && (
            <Redirect
              from={CONSUMER_ROUTES.LUCCA_CANCEL}
              to={CONSUMER_ROUTES.LUCCA_BUY}
            />
          )}
          {homePage && (
            <Redirect to={homePage.routing?.path + propagateUrlTracing()} />
          )}
        </Switch>
      </>
    )
  );
}
