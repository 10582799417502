import React, { useCallback, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Collapse,
  Container,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import {
  Warning,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@material-ui/icons";
import Title from "../../ui/Title";
import { addWeeks, format } from "date-fns";
import { RESERVATIONS_STATUS } from "../../utils/ecommerceConstants";
import { intlFormatMessage } from "../../utils/utils";
import { ecommerce } from "../../messages/ecommerce";
import { useIntl } from "react-intl";
import { DatePicker } from "../../ui/DatePicker";
import { common } from "../../messages";
import { it } from "date-fns/locale";
import { useEffect } from "react";
import { useBmapi } from "../../utils/bmapi-context";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function removeTime(date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

// COMPONENTE NON GENERICO PER IL MOMENTO

export default function ManageReservationsGen() {
  const classes = useRowStyles();
  const intl = useIntl();
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { bmapi } = useBmapi();
  const [openRows, setOpenRows] = useState([]);
  const [openNotes, setOpenNotes] = useState([]);
  const [closingDates, setClosingDates] = useState([]);
  const [filters, setFilters] = useState({
    from: new Date(),
    to: addWeeks(new Date(), 1),
    status: "x",
  });

  const businessID = bmapi.getUserInfo().business.id;

  const byFilters = (item) => {
    // da sistemare
    const date = removeTime(new Date(item.planned_delivery)).getTime(); // confronta solo le date senza orari
    const statusFilter = filters.status !== "x";
    const dateRange = date >= filters.from && date <= filters.to;

    if (!statusFilter && !filters.from && !filters.to) return item;
    else if (statusFilter && filters.from && filters.to)
      return item.status === filters.status && dateRange;
    else if (filters.from && filters.to) {
      return dateRange;
    } else if (statusFilter && filters.from) {
      return item.status === filters.status && date >= filters.from;
    } else if (statusFilter && filters.to) {
      return item.status === filters.status && date <= filters.to;
    } else if (statusFilter) {
      return item.status === filters.status;
    } else if (filters.from) {
      return date >= filters.from;
    } else if (filters.to) {
      return date <= filters.to;
    }
  };

  // Mi baso sull'oggetto ritornato da api
  // EORDER_GET: "/business/{business}/ecomorders"
  // utilizzato anche per gdsp per recuperare le prenotazioni
  const fakeReservations = [
    {
      id: "resId1",
      created_at: "0001-01-01T00:00:00Z",
      updated_at: "0001-01-01T00:00:00Z",
      deleted_at: "0001-01-01T00:00:00Z",
      tenant_id: 6294348160712791,
      planned_delivery: "2024-01-01T16:00:00Z", // giorno e ora della visita
      customer_id: "id1",
      items: null,
      amount: 0,
      business_id: "643bfe8b-bde8-4a30-9c65-4cf882aed4b7",
      status: 3,
      // Nota per musei: Se la data della visita è passata da una settimana, il checkbox della
      // conferma viene messo in disabled (non si può più cambiare lo status).
      // Status MySarca:
      // 0 Da preparare (che potrebbe essere qui "Da confermare")
      // 1 In progress
      // 2 Pronto
      // 3 Consegnato
      // 4 Cancellato
      // 5 Non ritirato
      customer_contacts: null,
      date: "20231004",
      slot_id: "yyyy-MM-ddhhMMhhMM-hhMMhhMM",
      delivery_mode: "",
      share_phone: false,
      content_id: "55e3db74-ce85-49b0-a5e7-75ef59410d03", // FIERA
      notes: "",
      business_name: "Museo XYZ",
      first_name: "simone",
      last_name: "Mainini",
      email: "email@email.com",
      mobile: "3317595880",
      content_name: "EVENTO SPOSI PISA", // FIERA
      wedding_date: "2019-10-05T00:00:00Z", // GDSP
      postponed_to: null,
      // I CAMPI SEGUENTI NON CI SONO ANCORA,
      // VALUTARE SE AGGIUNGERLI O SE SALVARLI DENTRO A CAMPI Già ESISTENTI
      group_type: "school", // school, association, touristic_operator, private
      group_name: "Liceo Carlo Porta",
      group_number: 27,
      visit_lang: "it",
    },
    {
      id: "resId2",
      created_at: "0001-01-01T00:00:00Z",
      updated_at: "0001-01-01T00:00:00Z",
      deleted_at: "0001-01-01T00:00:00Z",
      tenant_id: 6294348160712791,
      planned_delivery: "2024-03-21T16:00:00Z", // giorno e ora della visita
      customer_id: "id2",
      items: null,
      amount: 0,
      business_id: "643bfe8b-bde8-4a30-9c65-4cf882aed4b7",
      status: 0,
      // Status MySarca:
      // 0 Da preparare (che potrebbe essere qui "Da confermare")
      // 1 In progress
      // 2 Pronto
      // 3 Consegnato
      // 4 Cancellato
      // 5 Non ritirato
      customer_contacts: null,
      date: "20231004",
      slot_id: "yyyy-MM-ddhhMMhhMM-hhMMhhMM",
      delivery_mode: "",
      share_phone: false,
      content_id: "55e3db74-ce85-49b0-a5e7-75ef59410d03", // FIERA
      notes: "Una nota particolare.",
      business_name: "Museo XYZ",
      first_name: "simone",
      last_name: "Mainini",
      email: "email@email.com",
      mobile: "3317595880",
      content_name: "EVENTO SPOSI PISA", // FIERA
      wedding_date: "2019-10-05T00:00:00Z", // GDSP
      postponed_to: null,
      // I CAMPI SEGUENTI NON CI SONO ANCORA,
      // VALUTARE SE AGGIUNGERLI O SE SALVARLI DENTRO A CAMPI Già ESISTENTI
      group_type: "school", // school, association, touristic_operator, private
      group_name: "Liceo Carlo Porta",
      group_number: 27,
      visit_lang: "it",
    },
    {
      id: "resId3",
      created_at: "0001-01-01T00:00:00Z",
      updated_at: "0001-01-01T00:00:00Z",
      deleted_at: "0001-01-01T00:00:00Z",
      tenant_id: 6294348160712791,
      planned_delivery: "2024-03-22T16:00:00Z", // giorno e ora della visita
      customer_id: "id3",
      items: null,
      amount: 0,
      business_id: "643bfe8b-bde8-4a30-9c65-4cf882aed4b7",
      status: 0,
      // Status MySarca:
      // 0 Da preparare (che potrebbe essere qui "Da confermare")
      // 1 In progress
      // 2 Pronto
      // 3 Consegnato
      // 4 Cancellato
      // 5 Non ritirato
      customer_contacts: null,
      date: "20231004",
      slot_id: "yyyy-MM-ddhhMMhhMM-hhMMhhMM",
      delivery_mode: "",
      share_phone: false,
      content_id: "55e3db74-ce85-49b0-a5e7-75ef59410d03", // FIERA
      notes:
        "Lorem ipsum dolor sit amet, consectetur adipisci elit, sed do eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullamco laboriosam, nisi ut aliquid ex ea commodi consequatur. Duis aute irure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      business_name: "Museo XYZ",
      first_name: "simone",
      last_name: "Mainini",
      email: "email@email.com",
      mobile: "3317595880",
      content_name: "EVENTO SPOSI PISA", // FIERA
      wedding_date: "2019-10-05T00:00:00Z", // GDSP
      postponed_to: null,
      // I CAMPI SEGUENTI NON CI SONO ANCORA,
      // VALUTARE SE AGGIUNGERLI O SE SALVARLI DENTRO A CAMPI Già ESISTENTI
      group_type: "school", // school, association, touristic_operator, private
      group_name: "Liceo Carlo Porta",
      group_number: 27,
      visit_lang: "it",
    },
    {
      id: "resId4",
      created_at: "0001-01-01T00:00:00Z",
      updated_at: "0001-01-01T00:00:00Z",
      deleted_at: "0001-01-01T00:00:00Z",
      tenant_id: 6294348160712791,
      planned_delivery: "2024-03-12T16:00:00Z", // giorno e ora della visita
      customer_id: "id4",
      items: null,
      amount: 0,
      business_id: "643bfe8b-bde8-4a30-9c65-4cf882aed4b7",
      status: 0,
      // Status MySarca:
      // 0 Da preparare (che potrebbe essere qui "Da confermare")
      // 1 In progress
      // 2 Pronto
      // 3 Consegnato
      // 4 Cancellato
      // 5 Non ritirato
      customer_contacts: null,
      date: "20231004",
      slot_id: "yyyy-MM-ddhhMMhhMM-hhMMhhMM",
      delivery_mode: "",
      share_phone: false,
      content_id: "55e3db74-ce85-49b0-a5e7-75ef59410d03", // FIERA
      notes: "",
      business_name: "Museo XYZ",
      first_name: "simone",
      last_name: "Mainini",
      email: "email@email.com",
      mobile: "3317595880",
      content_name: "EVENTO SPOSI PISA", // FIERA
      wedding_date: "2019-10-05T00:00:00Z", // GDSP
      postponed_to: null,
      // I CAMPI SEGUENTI NON CI SONO ANCORA,
      // VALUTARE SE AGGIUNGERLI O SE SALVARLI DENTRO A CAMPI Già ESISTENTI
      group_type: "school", // school, association, touristic_operator, private
      group_name: "Liceo Carlo Porta",
      group_number: 27,
      visit_lang: "it",
    },
  ];

  useEffect(() => {
    bmapi.getBusiness(businessID).then((resp) => {
      setClosingDates(resp.closing_dates || []);
    });
  }, [bmapi, businessID]);

  const handleFilters = useCallback((label) => {
    const updateValue = (val) => {
      setFilters((v) => ({ ...v, [label]: val }));
    };

    return (i, f) => {
      if (typeof f === "boolean") updateValue(f);
      else if (i?.target) updateValue(i.target.value);
      else updateValue(i);
    };
  }, []);

  const handleToggleRow = (index) => {
    const newOpenRows = [...openRows];
    newOpenRows[index] = !newOpenRows[index];
    setOpenRows(newOpenRows);
  };

  const handleToggleNotes = (index) => {
    const newOpenNotes = [...openNotes];
    newOpenNotes[index] = !newOpenNotes[index];
    setOpenNotes(newOpenNotes);
  };

  const checkClosingDates = (date) => {
    const currentDateFormatted = format(new Date(date), "yyyyMMdd");

    // Verifica corrispondenze dirette
    const directMatch = closingDates.includes(currentDateFormatted);
    if (directMatch) {
      return true;
    }

    // Verifica corrispondenze nell'intervallo
    const rangeMatch = closingDates.some((d) => {
      if (d.includes("-")) {
        const [start, end] = d.split("-");
        return currentDateFormatted >= start && currentDateFormatted <= end;
      }
      return false;
    });

    return rangeMatch;
  };

  return (
    <Container maxWidth="lg">
      <Title>Prenotazioni</Title>
      <Box display="flex" flexDirection={smallScreen ? "column" : "row"}>
        <TextField
          margin="dense"
          value={filters.status}
          fullWidth
          onChange={handleFilters("status")}
          label={intl.formatMessage(ecommerce.status)}
          select
          InputLabelProps={{
            shrink: true,
          }}
        >
          {RESERVATIONS_STATUS.map((status) => (
            <MenuItem key={status.value} value={status.value}>
              {status.icon_small}{" "}
              {intlFormatMessage(ecommerce[status.label], status.label, intl)}
            </MenuItem>
          ))}
        </TextField>
        <DatePicker
          margin="dense"
          fullWidth
          onChange={handleFilters("from")}
          label={intl.formatMessage(common.from)}
          inputFormat="dd/MM/yyyy"
          value={filters.from}
          format="dd/MM/yy"
          renderInput={(params) => <TextField {...params} />}
          clearable
        />
        <DatePicker
          margin="dense"
          fullWidth
          onChange={handleFilters("to")}
          label={intl.formatMessage(common.to)}
          inputFormat="dd/MM/yyyy"
          value={filters.to}
          format="dd/MM/yy"
          renderInput={(params) => <TextField {...params} />}
          clearable
        />
      </Box>
      {!smallScreen && (
        <Box mt={2}>
          <Card>
            <CardContent>
              <TableContainer>
                <Table padding="none">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Conferma</TableCell>
                      <TableCell>Data e ora</TableCell>
                      <TableCell>Tipologia</TableCell>
                      <TableCell>Nome</TableCell>
                      <TableCell>Quantità</TableCell>
                      <TableCell>Lingua</TableCell>
                      <TableCell>Telefono</TableCell>
                      <TableCell>Email</TableCell>
                    </TableRow>
                  </TableHead>
                  {fakeReservations.filter(byFilters).map((r, index) => (
                    <TableBody key={r.id}>
                      <TableRow className={classes.root}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ padding: "6px 0" }}
                        >
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => handleToggleRow(index)}
                            disabled={!r.notes}
                          >
                            {openRows[index] ? (
                              <KeyboardArrowUp />
                            ) : (
                              <KeyboardArrowDown />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell padding="none">
                          <Checkbox
                            edge="start"
                            //checked={selection.includes(r)}
                            //onChange={(e) => handleChange(e, r)}
                            color="primary"
                            disabled={
                              new Date() >
                              addWeeks(new Date(r.planned_delivery), 1)
                            }
                          />
                        </TableCell>
                        <TableCell padding="none">
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            {checkClosingDates(r.planned_delivery) && (
                              <Tooltip title="La data della prenotazione coincide con un giorno di chiusura.">
                                <Warning
                                  fontSize="small"
                                  style={{ marginRight: 5 }}
                                  color="primary"
                                />
                              </Tooltip>
                            )}
                            <Typography>
                              {format(
                                new Date(r.planned_delivery),
                                "dd/MM/yy"
                              ) +
                                " " +
                                format(new Date(r.planned_delivery), "HH:mm")}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell padding="none">{r.group_type}</TableCell>
                        <TableCell padding="none">{r.group_name}</TableCell>
                        <TableCell padding="none">{r.group_number}</TableCell>
                        <TableCell padding="none">{r.visit_lang}</TableCell>
                        <TableCell padding="none">
                          <Link
                            onClick={() =>
                              window.open("tel:" + r.mobile, "_blank")
                            }
                            style={{
                              cursor: "pointer",
                              color: "black",
                              textDecoration: "underline",
                            }}
                          >
                            {r.mobile}
                          </Link>
                        </TableCell>
                        <TableCell padding="none">
                          <Link
                            style={{
                              color: "black",
                              textDecoration: "underline",
                            }}
                            href={`mailto:${r.email}`}
                          >
                            {r.email}
                          </Link>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={9}
                        >
                          <Collapse
                            in={openRows[index]}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box margin={1}>
                              <Typography>{"Note: " + r.notes}</Typography>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Box>
      )}
      {smallScreen && (
        <Box mt={2}>
          <Card>
            <List>
              {fakeReservations.filter(byFilters).map((r, index) => (
                <React.Fragment key={index}>
                  {index !== 0 && <Divider component="li" />}
                  <ListItem>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography style={{ fontWeight: 700 }}>
                            {checkClosingDates(r.planned_delivery) && (
                              <Tooltip title="La data della prenotazione coincide con un giorno di chiusura.">
                                <Warning
                                  style={{ marginRight: 5 }}
                                  color="primary"
                                />
                              </Tooltip>
                            )}
                            {`${format(
                              new Date(r.planned_delivery),
                              "EEEE dd MMMM",
                              {
                                locale: it,
                              }
                            )} | Ore: ${format(
                              new Date(r.planned_delivery),
                              "HH:mm"
                            )}`}
                          </Typography>
                          <Typography style={{ fontWeight: 700 }}>
                            Informazioni generali
                          </Typography>
                          <Typography>{`Tipo di visitatore: ${r.group_type}`}</Typography>
                          <Typography>{`Nome del gruppo: ${r.group_name}`}</Typography>
                          <Typography>{`Numero partecipanti: ${r.group_number}`}</Typography>
                          <Typography>{`Lingua della visita: ${r.visit_lang}`}</Typography>
                          <Typography>
                            <Link
                              onClick={() =>
                                window.open("tel:" + r.mobile, "_blank")
                              }
                              style={{
                                cursor: "pointer",
                                color: "black",
                                textDecoration: "underline",
                              }}
                            >
                              {r.mobile}
                            </Link>
                            {" - "}
                            <Link
                              style={{
                                color: "black",
                                textDecoration: "underline",
                              }}
                              href={`mailto:${r.email}`}
                            >
                              {r.email}
                            </Link>
                          </Typography>
                          {r.notes && (
                            <>
                              <Typography>
                                <Link
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleToggleNotes(index)}
                                >
                                  {!openNotes[index]
                                    ? "Mostra note"
                                    : "Nascondi note"}
                                </Link>
                              </Typography>
                              <Typography>
                                {openNotes[index] && r.notes}
                              </Typography>
                            </>
                          )}
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </React.Fragment>
              ))}
            </List>
          </Card>
        </Box>
      )}
    </Container>
  );
}
