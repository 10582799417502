import { defineMessages } from "react-intl";

export const form = defineMessages({
  address: {
    id: "common.address",
    defaultMessage: "Indirizzo",
  },
  attribution_text: {
    id: "form.attribution_text",
    defaultMessage: "Testo attribuzione",
  },
  attribution_link: {
    id: "form.attribution_link",
    defaultMessage: "Link attribuzione",
  },
  alternative_text: {
    id: "form.alternative_text",
    defaultMessage: "Testo alternativo",
  },
  autoJoin: {
    id: "common.form.autoJoin",
    defaultMessage: "Adesione automatica alla campagna",
  },
  banner: {
    id: "form.banner",
    defaultMessage: "Banner",
  },
  bannerClose: {
    id: "form.bannerClose",
    defaultMessage: "Chiusura automatica banner",
  },
  billingPeriod: {
    id: "common.form.billingPeriod",
    defaultMessage: "Durata abbonamento",
  },
  category: {
    id: "common.category",
    defaultMessage: "Categoria",
  },
  changeIcon: {
    id: "common.form.changeIcon",
    defaultMessage: "Cambia icona",
  },
  configAdd: {
    id: "common.form.configAdd",
    defaultMessage: "Aggiungi configurazione",
  },
  contact: {
    id: "common.form.contact",
    defaultMessage: "Contatta",
  },
  choosePrize: {
    id: "common.form.choosePrize",
    defaultMessage: "Scegli un premio",
  },
  coordinates: {
    id: "common.form.coordinates",
    defaultMessage: "Coordinate",
  },
  city: {
    id: "common.city",
    defaultMessage: "Città",
  },
  codes_prefix: {
    id: "common.codes_prefix",
    defaultMessage: "Prefisso per i codici",
  },
  company_name: {
    id: "component.form.company_name",
    defaultMessage: "Nome società",
  },
  constraints: {
    id: "component.form.constraints",
    defaultMessage: "Vincoli di utilizzo",
  },
  coverHelp: {
    id: "common.form.coverInfo",
    defaultMessage:
      "Per una migliore resa consigliamo di caricare un'immagine in formato {ratio} con una risoluzione di {width} * {height} pixel",
  },
  currency: {
    id: "common.form.currency",
    defaultMessage: "Valuta",
  },
  custom: {
    id: "common.form.shots.custom",
    defaultMessage: "Personalizzato",
  },
  deleteSelected: {
    id: "common.form.deleteSelected",
    defaultMessage: "Elimina selezionati",
  },
  demoCampaign: {
    id: "common.form.demoCampaign",
    defaultMessage: "Campagna demo",
  },
  demoStore: {
    id: "common.form.demoStore",
    defaultMessage: "Demo",
  },
  description: {
    id: "common.description",
    defaultMessage: "Descrizione",
  },
  deselectAll: {
    id: "common.deselectAll",
    defaultMessage: "Deseleziona tutti",
  },
  destination: {
    id: "common.destination",
    defaultMessage: "Destinazione",
  },
  details: {
    id: "component.form.details",
    defaultMessage: "Dettagli",
  },
  disabled: {
    id: "common.form.disabled",
    defaultMessage: "Disattivato",
  },
  discountCode: {
    id: "common.form.discountCode",
    defaultMessage: "Codice sconto",
  },
  invalidDiscountCode: {
    id: "common.form.invalidDiscountCode",
    defaultMessage: "Codice sconto non valido",
  },
  discountCodeApplied: {
    id: "common.form.discountCodeApplied",
    defaultMessage: "Codice sconto applicato: {code}",
  },
  dynamicValue: {
    id: "common.form.dynamicValue",
    defaultMessage: "Valore dinamico",
  },
  dynamicValueHelp: {
    id: "common.form.dynamicValueHelp",
    defaultMessage:
      "Opzione non disponibile quando la distribuzione con link è attiva",
  },
  earningMinimumThreshold: {
    id: "common.form.earningMinimumThreshold",
    defaultMessage: "Differenziale di spesa",
  },
  email: {
    id: "common.form.email",
    defaultMessage: "Indirizzo Email",
  },
  endDateError: {
    id: "common.form.endDateError",
    defaultMessage: "Termine antecedente all'inizio della campagna",
  },
  endHour: {
    id: "common.form.endHour",
    defaultMessage: "Ora di fine evento",
  },
  expiration: {
    id: "common.form.expiration",
    defaultMessage: "Scadenza: {expirationDate, date, short}",
  },
  expirationDate: {
    id: "common.form.expirationDate",
    defaultMessage: "Data termine distribuzione",
  },
  expirationDatePass: {
    id: "common.form.endEvent",
    defaultMessage: "Data di fine evento",
  },
  externalCodes: {
    id: "common.form.externalCodes",
    defaultMessage: "Utilizzo di codici esterni",
  },
  externalCodesText: {
    id: "common.form.externalCodes.text",
    defaultMessage: "Formato testo",
  },
  externalCodesQr: {
    id: "common.form.externalCodes.qrcode",
    defaultMessage: "QR Code",
  },
  friendly_url: {
    id: "common.form.friendly_url",
    defaultMessage: "Friendly URL",
  },
  friendly_url_disabled: {
    id: "common.form.friendly_url_disabled",
    defaultMessage: "Non disponibile per piano Trial",
  },
  friendly_url_exists: {
    id: "common.form.friendly_url_exists",
    defaultMessage: "L'URL inserito non è disponibile",
  },
  friendly_url_help: {
    id: "common.form.friendly_url_help",
    defaultMessage:
      "Nome composto da 8 a 20 caratteri minuscoli, numeri e trattini",
  },
  friendly_url_info: {
    id: "common.form.friendly_url_info",
    defaultMessage:
      "Questo URL rappresenta l'indirizzo sul quale i tuoi clienti troveranno la pagina del tuo negozio. Potrai utilizzarlo per inserirlo nelle tue comunicazioni mail, sul web e sui social.",
  },
  gracePeriod: {
    id: "common.form.gracePeriod",
    defaultMessage: "Tolleranza",
  },
  images: {
    id: "component.form.images",
    defaultMessage: "Immagini",
  },
  initialValue: {
    id: "common.form.initialValue",
    defaultMessage: "Valore iniziale",
  },
  insertAltMessage: {
    id: "common.form.insertAltMessage",
    defaultMessage: "Inserire testo del messaggio alternativo",
  },
  insertMessage: {
    id: "common.form.insertMessage",
    defaultMessage: "Inserire testo del messaggio",
  },
  insertQuantity: {
    id: "common.form.insertQuantity",
    defaultMessage: "Inserisci quantità",
  },
  latitude: {
    id: "common.form.latitude",
    defaultMessage: "Latitudine",
  },
  longitude: {
    id: "common.form.longitude",
    defaultMessage: "Longitudine",
  },
  linkDistribution: {
    id: "common.form.linkDistribution",
    defaultMessage: "Distribuzione con link",
  },
  logo_add: {
    id: "common.form.logo_add",
    defaultMessage: "Aggiungi un logo",
  },
  logoHelp: {
    id: "common.form.logoHelp",
    defaultMessage:
      "Per una resa migliore consigliamo un logo a sfondo trasparente",
  },
  loop: {
    id: "common.form.loop",
    defaultMessage: "Circuito",
  },
  managerRestriction: {
    id: "common.form.managerRestriction",
    defaultMessage: "Manager restrictions",
  },
  mainEvent: {
    id: "common.form.mainEvent",
    defaultMessage: "Evento principale di riferimento",
  },
  minExpiration: {
    id: "common.form.minExpiration",
    defaultMessage:
      "Scadenza minima necessaria: {expirationDate, date, medium}",
  },
  mobile: {
    id: "common.mobile",
    defaultMessage: "Telefono",
  },
  monthsNum: {
    id: "common.monthsNum",
    defaultMessage: "{months, plural, one {# mese} other {# mesi}}",
  },
  name: {
    id: "common.name",
    defaultMessage: "Nome",
  },
  noSelection: {
    id: "common.noSelection",
    defaultMessage: "Non hai ancora effettuato scelte",
  },
  completeName: {
    id: "common.form.completeName",
    defaultMessage: "Nome e cognome",
  },
  noCategory: {
    id: "common.noCategory",
    defaultMessage: "Nessuna categoria",
  },
  noLoop: {
    id: "common.form.noLoop",
    defaultMessage: "Nessun filtro",
  },
  none: {
    id: "common.form.none",
    defaultMessage: "Nessuno",
  },
  noPeriod: {
    id: "common.form.usePeriod.noPeriod",
    defaultMessage: "Nessun periodo",
  },
  noPrize: {
    id: "common.form.noPrizes",
    defaultMessage: "Nessun premio assegnabile",
  },
  order_summary: {
    id: "form.order_summary",
    defaultMessage: "Riepilogo ordine",
  },
  plan: {
    id: "common.form.plan",
    defaultMessage: "Tipo di abbonamento",
  },
  plan_help: {
    id: "common.form.plan_help",
    defaultMessage: "La fatturazione verrà effettuata al pagamento",
  },
  plan_trial: {
    id: "common.form.plan_trial",
    defaultMessage:
      "Sarà possibile effettuare upgrade alle versioni sbloccate prima della scadenza, dando continuità alle campagne già impostate",
  },
  preview: {
    id: "form.preview",
    defaultMessage: "Anteprima",
  },
  price: {
    id: "common.form.price",
    defaultMessage: "Prezzo",
  },
  primary_color: {
    id: "common.form.primary_color",
    defaultMessage: "Colore primario del tema",
  },
  primary_product_mandatory: {
    id: "common.form.primary_product_mandatory",
    defaultMessage: "Pass evento principale obbligatorio",
  },
  prizeDetail: {
    id: "common.form.prizeDetail",
    defaultMessage:
      "{threshold, plural, one {# punto} other {# punti}} = {prize}",
  },
  productLimitDateError: {
    id: "common.form.productLimitDateError",
    defaultMessage: "Scadenza prima della fine della campagna",
  },
  productsName: {
    id: "common.form.productsName",
    defaultMessage: "Nome dei prodotti",
  },
  program_name: {
    id: "common.program_name",
    defaultMessage: "Nome del programma di affiliazione",
  },
  province: {
    id: "common.province",
    defaultMessage: "Provincia",
  },
  push: {
    id: "form.push",
    defaultMessage: "Push",
  },
  pushNotification: {
    id: "form.pushNotification",
    defaultMessage: "Push Notification",
  },
  quantum: {
    id: "common.form.quantum",
    defaultMessage: "Scaglione di conversione spesa -> punti",
  },
  quantumHelp: {
    id: "common.form.quantumInfo",
    defaultMessage:
      "Scaglione di assegnazione dei punti (ogni XX euro di spesa assegna i punti)",
  },
  rate: {
    id: "common.form.rate",
    defaultMessage: "Tasso di conversione spesa -> punti",
  },
  rateHelp: {
    id: "common.form.rateInfo",
    defaultMessage:
      "Tasso di conversione spesa -> punti (es: quanti euro per guadagnare un punto)",
  },
  reimbursement: {
    id: "common.reimbursement",
    defaultMessage: "Rimborso",
  },
  region: {
    id: "common.region",
    defaultMessage: "Regione",
  },
  searchByLocation: {
    id: "common.form.searchByLocation",
    defaultMessage: "Ricerca per posizione",
  },
  searchByQrCode: {
    id: "common.form.searchByQrCode",
    defaultMessage: "Ricerca per QR CODE",
  },
  searchParams: {
    id: "common.form.searchParams",
    defaultMessage: "Parametri di ricerca",
  },
  searchResults: {
    id: "common.form.searchResults",
    defaultMessage: "Risultati della ricerca",
  },
  selectAll: {
    id: "common.form.selectAll",
    defaultMessage: "Seleziona tutti",
  },
  selectCity: {
    id: "common.form.selectCity",
    defaultMessage: "Selezionare una città",
  },
  selectDate: {
    id: "common.form.selectDate",
    defaultMessage: "Seleziona data",
  },
  selectTime: {
    id: "common.form.selectTime",
    defaultMessage: "Seleziona orario",
  },
  selectPeriod: {
    id: "common.form.selectPeriod",
    defaultMessage: "Seleziona periodo",
  },
  shots: {
    id: "common.form.shots",
    defaultMessage: "Numero di utilizzi",
  },
  shotsCustom: {
    id: "common.form.shotsCustom",
    defaultMessage: "Numero di utilizzi personalizzato",
  },
  singleUse: {
    id: "common.form.shots.single",
    defaultMessage: "Uso singolo",
  },
  slug: {
    id: "common.form.slug",
    defaultMessage: "Friendly Url",
  },
  startDate: {
    id: "common.form.startDate",
    defaultMessage: "Data di inizio campagna",
  },
  startDatePass: {
    id: "common.form.startEvent",
    defaultMessage: "Data di inizio evento",
  },
  startHour: {
    id: "common.form.startHour",
    defaultMessage: "Ora di inizio evento",
  },
  storesNum: {
    id: "form.storesNum",
    defaultMessage: "{stores, plural, one {# negozio} other {# negozi}}",
  },
  storesQuantity: {
    id: "form.storesQuantity",
    defaultMessage: "Quantità di negozi",
  },
  subtitle: {
    id: "form.subtitle",
    defaultMessage: "Sottotitolo",
  },
  threshold: {
    id: "common.form.threshold",
    defaultMessage: "Punti",
  },
  title: {
    id: "form.title",
    defaultMessage: "Titolo",
  },
  tosConsumerUrl: {
    id: "common.form.tosConsumerUrl",
    defaultMessage: "URL dei termini di servizio per i clienti",
  },
  tosMerchantUrl: {
    id: "common.form.tosMerchantUrl",
    defaultMessage: "URL dei termini di servizio per i manager",
  },
  type: {
    id: "common.form.type",
    defaultMessage: "Tipo di campagna",
  },
  unlimited: {
    id: "common.form.shots.unlimited",
    defaultMessage: "Illimitati",
  },
  useFrequency: {
    id: "common.form.useFrequency",
    defaultMessage: "Numero massimo utilizzi nel periodo",
  },
  usePeriod: {
    id: "common.form.usePeriod",
    defaultMessage: "Periodo",
  },
  usePeriodHelp: {
    id: "common.form.usePeriodHelpText",
    defaultMessage: "Periodo di tempo",
  },
  value: {
    id: "common.form.value",
    defaultMessage: "Valore",
  },
  valueSingle: {
    id: "common.form.valueSingle",
    defaultMessage: "Valore del singolo utilizzo",
  },
  vatNumber: {
    id: "common.vatNumber",
    defaultMessage: "Partita IVA",
  },
  fiscalCode: {
    id: "common.fiscalCode",
    defaultMessage: "Codice fiscale",
  },
  invoiceCode: {
    id: "common.invoiceCode",
    defaultMessage: "Codice SDI",
  },
  invoiceEmail: {
    id: "common.invoiceEmail",
    defaultMessage: "PEC",
  },
  invoiceAddress: {
    id: "common.invoiceAddress",
    defaultMessage: "Indirizzo di fatturazione",
  },
  waiting: {
    id: "common.form.waitingList",
    defaultMessage: "Lista d'attesa",
  },
  zip: {
    id: "common.zip",
    defaultMessage: "CAP",
  },
  defaultLanguage: {
    id: "common.defaultLanguage",
    defaultMessage: "Lingua predefinita",
  },
  otherSupportedLanguages: {
    id: "common.otherSupportedLanguages",
    defaultMessage: "Altre lingue supportate",
  },
  optionalPoiFields: {
    id: "common.optionalPoiFields",
    defaultMessage: "Campi opzionali dei punti d'interesse",
  },
  withAuthentication: {
    id: "common.withAuthentication",
    defaultMessage: "{entity} privato",
  },
  messageAuthentication: {
    id: "common.messageAuthentication",
    defaultMessage:
      "Selezionando questo {entity} come privato lo si renderà visibile solo agli utenti loggati alla piattaforma",
  },
  withLocation: {
    id: "common.withLocation",
    defaultMessage: "Località",
  },
  withActivityType: {
    id: "common.withActivityType",
    defaultMessage: "Tipo (contenuto culturale, ludico...)",
  },
  withOutdoor: {
    id: "common.withOutdoor",
    defaultMessage: "All'aperto",
  },
  withTourTransport: {
    id: "common.withTourTransport",
    defaultMessage: "Trasporto (a piedi, in bicicletta...)",
  },
  withTourPrices: {
    id: "common.withTourPrices",
    defaultMessage: "Percorsi con prezzo",
  },
  emailWrongFormat: {
    id: "common.emailWrongFormat",
    defaultMessage: "Formato email non corretto",
  },
  invoiceCodeWrongFormat: {
    id: "common.invoiceCodeWrongFormat",
    defaultMessage: "Formato codice SDI non corretto",
  },
  vatNumberWrongFormat: {
    id: "common.vatNumberWrongFormat",
    defaultMessage: "Formato partita IVA non corretto",
  },
  fiscalCodeWrongFormat: {
    id: "common.fiscalCodeWrongFormat",
    defaultMessage: "Formato codice fiscale non corretto",
  },
  postalCodeWrongFormat: {
    id: "common.postalCodeWrongFormat",
    defaultMessage: "Formato CAP non corretto",
  },
  zoom: {
    id: "common.form.zoom",
    defaultMessage: "Zoom",
  },
  showDisabled: {
    id: "common.form.showDisabled",
    defaultMessage: "Mostra disabilitati",
  },
  showDeleted: {
    id: "common.form.showDeleted",
    defaultMessage: "Mostra eliminati",
  },
});
